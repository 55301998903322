.img-overlay {
  filter: grayscale() brightness(160%);
  object-fit: cover;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  position: absolute;
}

.stickman-scene {
  opacity: 0;
}

.topbar {
  color: var(--text-color);
  width: 100%;
  z-index: 200;
  visibility: hidden;
  opacity: 0;
  justify-content: space-between;
  padding: 20px 25px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.topbar img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.topbar .nav a {
  cursor: pointer;
  margin-left: 15px;
  font-size: 1rem;
}

.nav__item-hover-inner[data-hover] {
  color: var(--text-color);
  position: relative;
}

.nav__item-hover-inner:before {
  content: attr(data-hover);
  width: 0;
  white-space: nowrap;
  color: var(--primary);
  transition: all .3s cubic-bezier(1, 0, 0, 1);
  position: absolute;
  top: -2px;
  left: 0;
  overflow: hidden;
}

.nav__item-hover-inner:after {
  content: attr(data-hover);
  width: 0;
  white-space: nowrap;
  color: var(--text-color);
  color: var(--text-color);
  transition: all .3s cubic-bezier(1, 0, 0, 1) .2s;
  position: absolute;
  top: -2px;
  left: 0;
  overflow: hidden;
}

.nav__item-hover:hover .nav__item-hover-inner:after, .nav__item-hover:hover .nav__item-hover-inner:before {
  width: 100%;
}

.greeting, .greeting h1 {
  transform-origin: 0;
}

.greeting {
  color: var(--dark-1);
  transform-origin: 0 0;
  z-index: 2;
  position: absolute;
  top: 5%;
  left: 80px;
  transform: scale(.95);
}

.greeting h1 {
  height: 95px;
  visibility: hidden;
  opacity: 0;
  margin-bottom: .1em;
  font-family: robotoMono;
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1;
}

.greeting p {
  width: 471px;
  visibility: hidden;
  opacity: 0;
  font-size: 1rem;
}

.scene {
  width: 100%;
  height: 735px;
  height: 100vh;
  min-height: 600px;
  position: relative;
  overflow: hidden;
}

.scene svg {
  width: 1000px;
  margin-top: 100px;
  margin-left: 32%;
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-65%);
}

.tech-icon {
  pointer-events: none;
}

.icon-text {
  cursor: pointer;
  font-size: 18px;
}

.greensock-text {
  fill: #000;
  font-weight: lighter;
}

.mountain, .hill-3, .hill-2, .hill-1 {
  filter: grayscale();
}

g#columns, #columns1, #columns2, #columns3 {
  display: none;
}

.mountain .face {
  fill: var(--dark-1);
}

.z-road {
  fill: #d3d3d3;
}

.road-line {
  stroke: #d3d3d3;
}

@media screen and (max-width: 1450px) {
  .page .scene svg {
    right: unset;
    margin-left: 30%;
  }
}

@media screen and (max-width: 1020px) {
  .page .scene svg, .page .scene .pixelated {
    right: unset;
    margin-left: 25.8%;
  }
}

@media screen and (max-width: 1080px) {
  .greeting {
    left: 50px;
  }

  .greeting p {
    font-size: 1rem;
  }

  .greeting h1 {
    font-size: 4.5rem;
  }

  .scene svg {
    right: -260px;
  }
}

@media screen and (max-width: 935px) {
  .page .scene, .img-overlay {
    min-height: 1079px;
  }

  .page .scene svg {
    top: 760px;
    right: -290px;
  }

  .page .container .img-overlay {
    opacity: 0;
  }

  .greeting {
    top: 270px;
  }
}

@media screen and (max-width: 600px) {
  .topbar {
    flex-direction: column;
  }

  .topbar .nav {
    width: 100%;
    height: 60px;
    background-color: var(--primary);
    justify-content: space-evenly;
    align-items: center;
    padding: 0 30px;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .topbar .nav a {
    margin-left: 0;
  }

  .greeting {
    left: 40px;
  }

  .greeting p {
    width: 445px;
  }
}

@media screen and (max-width: 452px) {
  .topbar .nav {
    padding: 0 4px;
  }

  .greeting {
    top: 200px;
  }

  .greeting h1 {
    font-size: 3.8rem;
  }

  .greeting p {
    width: 340px;
  }
}

@media screen and (max-width: 385px) {
  .page .scene svg {
    right: -311px;
  }
}

@media screen and (max-width: 375px) {
  .greeting {
    top: 200px;
    left: 20px;
  }

  .greeting p {
    width: 316px;
  }
}

@media screen and (max-width: 320px) {
  .greeting h1 {
    font-size: 3rem;
  }

  .topbar .nav a {
    font-size: .8rem;
  }

  .page .scene svg {
    width: 850px;
    right: -273px;
  }
}

/*# sourceMappingURL=index.6e7b8593.css.map */
