.img-overlay {
  position: absolute;
  filter: grayscale(1) brightness(160%);
  object-fit: cover;
  width: 100%;
  height: 100vh;
  min-height: 600px;
}
.stickman-scene {
  opacity: 0;
}

.topbar {
  color: var(--text-color);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 25px 0;
  display: flex;
  justify-content: space-between;
  z-index: 200;
  visibility: hidden;
  opacity: 0;
}

.topbar img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.topbar .nav a {
  font-size: 1rem;
  cursor: pointer;
  margin-left: 15px;
  /* mix-blend-mode: difference; */
}

.nav__item-hover-inner[data-hover] {
  position: relative;
  color: var(--text-color);
}

.nav__item-hover-inner::before {
  content: attr(data-hover);
  position: absolute;
  top: -2px;
  left: 0;
  width: 0px;
  overflow: hidden;
  white-space: nowrap;
  color: var(--primary);
  transition: all cubic-bezier(1, 0, 0, 1) 0.3s;
}

.nav__item-hover-inner::after {
  content: attr(data-hover);
  position: absolute;
  top: -2px;
  left: 0;
  width: 0px;
  overflow: hidden;
  white-space: nowrap;
  color: var(--text-color);
  color: var(--text-color);
  transition: all cubic-bezier(1, 0, 0, 1) 0.3s 0.2s;
}

.nav__item-hover:hover .nav__item-hover-inner::after,
.nav__item-hover:hover .nav__item-hover-inner::before {
  width: 100%;
}

.greeting,
.greeting h1 {
  transform-origin: left;
}

.greeting {
  color: var(--dark-1);
  transform: scale(0.95);
  transform-origin: left top;
  position: absolute;
  top: 5%;
  left: 80px;
  /* transition: all 0.3s ease-out; */
  z-index: 2;
}

.greeting h1 {
  font-family: robotoMono;
  font-weight: 600;
  font-size: 4.5rem;
  margin-bottom: 0.1em;
  line-height: 1;
  height: 95px;
  visibility: hidden;
  opacity: 0;
}

.greeting p {
  font-size: 1rem;
  width: 471px;
  visibility: hidden;
  opacity: 0;
}

.scene {
  position: relative;
  width: 100%;
  height: 735px;
  height: 100vh;
  min-height: 600px;
  overflow: hidden;
}

.scene svg {
  position: absolute;
  /* bottom: 0; */
  top: 50%;
  transform: translateY(-65%);
  right: -25px;
  width: 1000px;
  margin-top: 100px;
  margin-top: 100px;
  margin-left: 32%;
}

.tech-icon {
  pointer-events: none;
}

.icon-text {
  font-size: 18px;
  cursor: pointer;
}

.greensock-text {
  fill: black;
  font-weight: lighter;
}

/* font-family="robotoMono, monospace" font-size="20px" fill="black" x="50" y="50" */

.mountain,
.hill-3,
.hill-2,
.hill-1 {
  filter: grayscale(1);
}

g#columns,
#columns1,
#columns2,
#columns3 {
  display: none;
}

.mountain .face {
  fill: var(--dark-1);
}

.z-road {
  fill: lightgrey;
}

.road-line {
  stroke: lightgrey;
}

@media screen and (max-width: 1450px) {
  .page .scene svg {
    right: unset;
    margin-left: 29.2%;
    margin-left: 30%;
  }
}

@media screen and (max-width: 1020px) {
  .page .scene svg,
  .page .scene .pixelated {
    right: unset;
    margin-left: 29.2%;
    margin-left: 25.8%;
  }
}

@media screen and (max-width: 1080px) {
  .greeting {
    left: 50px;
  }
  .greeting p {
    font-size: 1rem;
    /* transition: all 0.2s ease-out; */
  }
  .greeting h1 {
    font-size: 4.5rem;
    /* transition: all 0.2s ease-out; */
  }
  .scene svg {
    right: -260px;
  }
}

@media screen and (max-width: 935px) {
  .page .scene,
  .img-overlay {
    min-height: 1079px;
  }
  .page .scene svg {
    top: 760px;
    right: -290px;
  }
  .page .container .img-overlay {
    opacity: 0;
  }

  .greeting {
    top: 270px;
  }
}

@media screen and (max-width: 600px) {
  .topbar {
    flex-direction: column;
  }
  .topbar .nav {
    position: fixed;
    bottom: 0px;
    left: 0;
    padding: 0 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: var(--primary);
  }
  .topbar .nav a {
    margin-left: 0;
  }

  .greeting {
    left: 40px;
  }
  .greeting p {
    width: 445px;
  }
}

@media screen and (max-width: 452px) {
  .topbar .nav {
    padding: 0 4px;
  }
  .greeting {
    top: 200px;
  }
  .greeting h1 {
    font-size: 3.8rem;
  }
  .greeting p {
    width: 340px;
  }
}

@media screen and (max-width: 385px) {
  .page .scene svg {
    right: -311px;
  }
}

@media screen and (max-width: 375px) {
  .greeting {
    top: 200px;
    left: 20px;
  }
  .greeting p {
    width: 316px;
  }
}

@media screen and (max-width: 320px) {
  .greeting h1 {
    font-size: 3rem;
  }

  .topbar .nav a {
    font-size: 0.8rem;
  }

  .page .scene svg {
    right: -273px;
    width: 850px;
  }
}
